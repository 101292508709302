import * as React from "react";
import { FC } from "react";

import { Flex, Box } from "@chakra-ui/react";

import Image from "@components/Image";

interface IProductOfferMediaCollageProps {
  media: any;
  productName: string;
}

const ProductOfferMediaCollage: FC<IProductOfferMediaCollageProps> = ({ media, productName }) => {
  const largeImage = media?.[0]?.path;
  const smallImageTop = media?.[1]?.path;
  const smallImageBottom = media?.[2]?.path;

  return (
    <Box width={{ base: "100%", md: "584px", lg: "528px" }} mx={{ base: "auto", lg: "unset" }}>
      <Flex
        style={{
          borderRadius: "1rem",
          overflow: "hidden",
        }}
        height={{ base: "200px", md: "360px" }}
        gap={2}
      >
        <Flex bg="gray.50" width="calc(100% / 3 * 2)" justifyContent="center" alignItems="center">
          <Box width="90%" height="90%" position="relative">
            <Image
              src={largeImage}
              alt={`${productName}-1`}
              draggable={false}
              objectFit="contain"
              layout="fill"
              m="auto"
              priority
            />
          </Box>
        </Flex>
        <Flex flexDirection="column" width="calc(100% / 3)" gap={2}>
          <Flex bg="gray.50" height="50%" justifyContent="center" alignItems="center">
            <Box width="85%" height="85%" position="relative">
              <Image
                src={smallImageTop}
                alt={`${productName}-2`}
                draggable={false}
                objectFit="contain"
                layout="fill"
                priority
              />
            </Box>
          </Flex>
          <Flex bg="gray.50" height="50%" justifyContent="center" alignItems="center">
            <Box width="85%" height="85%" position="relative">
              <Image
                src={smallImageBottom}
                alt={`${productName}-3`}
                draggable={false}
                objectFit="contain"
                layout="fill"
                priority
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProductOfferMediaCollage;

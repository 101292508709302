import * as React from "react";
import { FC } from "react";

import { Flex, Box } from "@chakra-ui/react";

import Image from "@components/Image";

interface IProductOfferImageProps {
  productName: string;
  imageSrc: string;
}

const ProductOfferImage: FC<IProductOfferImageProps> = ({ productName, imageSrc }) => {
  return (
    <Box width={{ base: "100%", md: "584px", lg: "528px" }} mx={{ base: "auto", lg: "unset" }}>
      <Flex
        bg="gray.50"
        style={{
          borderRadius: "1rem",
          display: "flex",
          position: "relative",
          overflow: "hidden",
        }}
        height={{ base: "200px", md: "360px" }}
      >
        <Image src={imageSrc} alt={productName} draggable={false} objectFit="contain" layout="fill" priority />
      </Flex>
    </Box>
  );
};

export default ProductOfferImage;

import React, { FC, ReactNode } from "react";

import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";

import { BlogList, BlogDetails } from "@components/blog";
import FeaturesListPage from "@components/ResourceView/components/Page/components/FeaturesListPage";
import PlansListPage from "@components/ResourceView/components/Page/components/PlansListPage";
import ReferralLayout from "@components/ResourceView/components/Page/layouts/ReferralLayout";
import VehicleSelectorPageLayout from "@components/ResourceView/components/Page/layouts/VehicleSelectorPageLayout";
import { IPageData } from "@components/ResourceView/types";
import { VehicleOcaDetails } from "@components/Vehicles/components/VehicleOcaDetails/VehicleOcaDetails";
import { TVehiclesProps } from "@components/Vehicles/Vehicles";
import { PageType } from "@features/cms/types";

import CompareLanding from "./components/CompareLanding";
import BlogLayout from "./layouts/BlogLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import LegalPageLayout from "./layouts/LegalPageLayout";

/**
 * @TODO - FIX SSR ISSUES WITH VEHICLES COMPONENT
 * Temporary solution to avoid SSR issues with Vehicles component
 * because there is window object usage in it.
 */
const Vehicles = dynamic<TVehiclesProps>(() => import("@components/Vehicles/Vehicles").then((mod) => mod.Vehicles), {
  ssr: false,
});

const Customizations = dynamic<TVehiclesProps>(
  () => import("@components/Vehicles/Customizations/Customizations").then((mod) => mod.Customizations),
  {
    ssr: false,
  }
);

interface IPageProps {
  pageName: string;
  pageType: PageType;
  pageData: IPageData;
  children: ReactNode;
}

const Page: FC<IPageProps> = ({ pageName, pageType, pageData, children }) => {
  const { t } = useTranslation();

  const renderPageLayout = () => {
    switch (pageType) {
      case PageType.LEGAL:
        return <LegalPageLayout pageData={pageData}>{children}</LegalPageLayout>;
      case PageType.FEATURES_LANDING: {
        return (
          <VehicleSelectorPageLayout
            pageData={pageData}
            title={t("features:landing-title")}
            subtitle={t("features:landing-subtitle")}
            description={t("features:landing-description")}
          >
            {children}
          </VehicleSelectorPageLayout>
        );
      }
      case PageType.PLANS_LANDING: {
        return (
          <VehicleSelectorPageLayout
            pageData={pageData}
            title={t("plans:landing-title")}
            subtitle={t("plans:landing-subtitle")}
            description={t("plans:landing-description")}
          >
            {children}
          </VehicleSelectorPageLayout>
        );
      }
      case PageType.COMPARE_LANDING: {
        return <CompareLanding pageData={pageData} />;
      }
      case PageType.FEATURES_LIST: {
        return (
          <FeaturesListPage pageName={pageName} pageData={pageData}>
            {children}
          </FeaturesListPage>
        );
      }
      case PageType.PLANS_LIST: {
        return <PlansListPage pageData={pageData}>{children}</PlansListPage>;
      }
      case PageType.VEHICLES_LANDING:
      case PageType.VEHICLE_SUPPORTED_APP_LIST:
        return <Vehicles pageData={pageData} pageSectionContent={children} />;
      case PageType.VEHICLE_OCA: {
        if (!pageData?.oca) {
          return null;
        }

        return <VehicleOcaDetails {...pageData?.oca} pageContent={children} />;
      }
      case PageType.BLOG_LANDING:
      case PageType.BLOG_CATEGORY: {
        const activeCategory = pageData?.blogData?.categories?.find(
          ({ slug }) => slug === pageData.blogData?.currentCategory
        );

        return (
          <BlogLayout
            categories={pageData.blogData?.categories}
            currentCategory={pageData.blogData?.currentCategory}
            containerChildren={
              <BlogList
                data={pageData.blogData?.blogPosts ?? undefined}
                currentCategory={pageData.blogData?.currentCategory}
                activeCategory={activeCategory}
                isPageBlogLanding={pageData.blogData?.isPageBlogLanding}
              />
            }
            isOutsideContainer
          >
            {children}
          </BlogLayout>
        );
      }
      case PageType.BLOG_POST: {
        return (
          <BlogLayout categories={pageData.blogData?.categories} currentCategory={pageData.blogData?.currentCategory}>
            <BlogDetails blog={pageData.blogData?.blogDetails}>{children}</BlogDetails>
          </BlogLayout>
        );
      }
      case PageType.REFERRALS:
        return (
          <ReferralLayout disableMarginY={PageType.REFERRALS === pageType || PageType.STANDALONE === pageType}>
            {children}
          </ReferralLayout>
        );
      case PageType.CUSTOMIZATIONS_LANDING:
      case PageType.CUSTOMIZATIONS_SUPPORTED_APP_LIST:
        return <Customizations pageData={pageData} pageSectionContent={children} />;
      default:
        return (
          <DefaultLayout disableMarginY={PageType.HOMEPAGE === pageType || PageType.STANDALONE === pageType}>
            {children}
          </DefaultLayout>
        );
    }
  };

  return renderPageLayout();
};

export default Page;

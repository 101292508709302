import { FC, PropsWithChildren } from "react";

import { Flex, Box } from "@chakra-ui/react";
import NextImage from "next/image";

import { VERTICAL_ALIGN_VALUE } from "@components/ResourceView/components/constants";
import { SideImageContext } from "@components/ResourceView/components/utils";
import { useIsMobile } from "@utils/hooks/hooks";
import { isNullOrUndefined } from "@utils/utils";
import { IBlock } from "features/cms/types";
import { IImage } from "features/types";

import { getBlockWithImageStyles } from "../utils/blockWithImageUtils";

type TBlockWithImage = Omit<IBlock, "image"> & { image: IImage };

interface IBlockWithImageProps {
  block: TBlockWithImage;
  hasSideImagePlaceholder?: boolean;
  isFeatureBlock?: boolean;
}

export const BlockWithImage: FC<PropsWithChildren<IBlockWithImageProps>> = ({
  block,
  hasSideImagePlaceholder,
  children,
  isFeatureBlock = false,
}) => {
  const { code, width, backgroundColor, image, name, minHeight } = block;
  const isMobile = useIsMobile();
  const { blockContainer, imageBorderRadiusStyles, paddingByImagePosition } = getBlockWithImageStyles(block, isMobile);

  return (
    <Box
      py={4}
      width={{ base: "full", md: `${width}%` }}
      {...(backgroundColor
        ? {
            sx: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              "--block-bg-color": backgroundColor,
            },
          }
        : {})}
    >
      <Flex
        bgColor={backgroundColor}
        gap={8}
        overflow="hidden"
        {...blockContainer}
        {...(!isNullOrUndefined(minHeight)
          ? {
              minHeight,
            }
          : {})}
      >
        <Flex
          pos="relative"
          flex={1}
          display={{ base: hasSideImagePlaceholder ? "none" : "flex", md: "flex" }}
          {...imageBorderRadiusStyles}
          overflow="hidden"
        >
          {image?.path && <NextImage src={image.path} alt={name} draggable={false} width={719} height={395} priority />}
        </Flex>
        <Flex
          w="full"
          flex={1}
          key={code}
          flexDir="column"
          alignSelf={VERTICAL_ALIGN_VALUE[block.verticalAlign || "top"]}
        >
          <Box
            textAlign={{ base: block.centerTextContentForMobile ? "center" : "unset", md: "unset" }}
            {...paddingByImagePosition}
            {...(isFeatureBlock
              ? {
                  padding: { base: 6, md: 8 },
                }
              : {})}
          >
            <SideImageContext.Provider value={image.path}>{children}</SideImageContext.Provider>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

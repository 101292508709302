/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, PropsWithChildren } from "react";

import { Box, HStack, useBreakpointValue } from "@chakra-ui/react";
import NextImage from "next/image";

interface IHeroProps {
  background?: string;
  object?: string;
}

const useMq = ({ base = {}, lg = {}, xl = {} }) => {
  return {
    ...base,
    "@media screen and (min-width: 991px)": {
      ...lg,
    },
    "@media screen and (min-width: 1280px)": {
      ...xl,
    },
  };
};

const Hero: FC<PropsWithChildren<IHeroProps>> = ({
  background = "/assets/hero/bg2.png",
  object = "/assets/hero/objects.png",
  children,
}) => {
  // Responsive styles
  const styles = {
    badges: {
      gap: useBreakpointValue({ base: 2, lg: 3 }),
      size: useBreakpointValue({ base: 28, lg: 42 }),
    },
    buttons: {
      offset: useBreakpointValue({ base: 0, lg: 16 }),
    },
    objectWrapper: {
      width: useBreakpointValue({ base: "100%", lg: "65%" }),
    },
    object: {
      position: "absolute" as string,

      layout: useBreakpointValue({ base: "responsive", lg: "fill" }) as "responsive" | "fill",
      width: useBreakpointValue({ base: 1000, lg: "100%" }),
      height: useBreakpointValue({ base: 1000, lg: "100%" }),

      display: useBreakpointValue({ base: "block", lg: "none" }),
    },
  };

  return (
    <Box
      style={{
        position: "relative",
      }}
      sx={useMq({
        base: {
          padding: "122px 23px 20px 23px",
        },
        lg: {
          maxHeight: "900px",
          padding: "265px 80px 244px 80px",
        },
        xl: {
          padding: "265px 164px 244px 164px",
        },
      })}
    >
      {/* Background image */}
      <Box zIndex={-2} pos="absolute" top="0" left="0" width="100%" height="100%">
        <NextImage
          src={background}
          alt=""
          quality={85}
          width={1920}
          height={1080}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 100% calc(100% - 200px), 0 100%)",
          }}
        />
      </Box>

      <Box
        display="flex"
        flexDir={{
          base: "column",
          lg: "row",
        }}
      >
        <HStack
          w="full"
          maxW="full"
          textAlign={{ base: "center", lg: "unset" }}
          sx={{ a: { width: "auto" } }}
          alignSelf="start"
        >
          {children}
        </HStack>

        <Box
          zIndex={-1}
          height="100%"
          pos={{
            base: "relative",
            lg: "absolute",
          }}
          right={0}
          top={10}
          sx={useMq({
            base: {
              width: "100%",
            },
            lg: {
              width: "65%",
              bottom: "-5%",
            },
          })}
        >
          <NextImage
            priority
            src={object}
            width={426}
            height={426}
            alt=""
            quality={85}
            style={{
              width: styles.object.width,
              height: "100%",
              objectFit: "contain",
              objectPosition: "right bottom",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;

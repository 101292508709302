import React, { FC, ReactNode } from "react";

import { Flex } from "@chakra-ui/react";

interface ISectionProps {
  children: ReactNode;
}
const MainFeatureSection: FC<ISectionProps> = ({ children }) => {
  return (
    <Flex
      width="full"
      maxW="container.xl"
      margin="0 auto"
      flexWrap="wrap"
      bgSize="cover"
      bgPos="center"
      position="relative"
      zIndex={0}
    >
      <Flex
        width="full"
        maxW="container.xl"
        margin="0 auto"
        zIndex={1}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={{ base: "center", md: "unset" }}
        flex={1}
        flexWrap="wrap"
        px={4}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default MainFeatureSection;

import * as React from "react";
import { FC, PropsWithChildren } from "react";

import { Text, Flex, Box, Button } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import NextLink from "next/link";

import Refund from "@assets/icons/Refund.svg";
import ProductPriceTag from "@components/product/ProductPriceTag";
import { useAppStaticPath } from "@context/AppStaticPathContext";

import ProductOfferImage from "./ProductOfferImage";
import ProductOfferMediaCollage from "./ProductOfferMediaCollage";

interface IProductOfferBlockProps {
  product?: any;
  hasImageCollage?: boolean;
  index?: number;
}

const ProductOfferBlock: FC<PropsWithChildren<IProductOfferBlockProps>> = ({
  product,
  hasImageCollage,
  index = 0,
  children,
}) => {
  const { t } = useTranslation();
  const { getProductPath } = useAppStaticPath();
  const imageSrc = product?.images?.[0]?.path;
  const isRowReversed = index % 2 !== 0;

  const startDate = new Date(product?.startDate);
  const endDate = new Date(product?.endDate);
  const currentDate = new Date();
  const isProductDisabled = (startDate && startDate > currentDate) || (endDate && endDate < currentDate);

  const renderProductImage = () => {
    if (hasImageCollage && product?.media) {
      const collageImages = product?.media.filter((image) => image?.isCollageImage);

      if (collageImages.length >= 3) {
        return <ProductOfferMediaCollage media={collageImages} productName={product.name} />;
      }
    }

    return imageSrc ? <ProductOfferImage imageSrc={imageSrc} productName={product.name || ""} /> : null;
  };

  return product && !isProductDisabled ? (
    <Flex
      width="full"
      maxW="container.xl"
      margin="0 auto"
      flexWrap="wrap"
      bgSize="cover"
      bgPos="center"
      position="relative"
      zIndex={0}
      mb={10}
    >
      <Flex
        width="full"
        maxW="container.xl"
        margin="0 auto"
        zIndex={1}
        flexDirection={{ base: "column", md: isRowReversed ? "row" : "row-reverse" }}
        justifyContent={{ base: "center", md: "space-between" }}
        flex={1}
        flexWrap={{ base: "nowrap", md: "wrap", lg: "nowrap" }}
        px={4}
        gap={8}
      >
        <Box width={{ base: "100%", md: "90%", lg: `calc(${50}% - 3rem)` }} mx={{ base: "auto", lg: "unset" }}>
          <Flex flexDirection="column" justifyContent="center" height="100%" gap={8}>
            <Box>{children}</Box>
            <Box display={{ base: "none", lg: "block" }}>
              <Flex>
                <ProductPriceTag product={product} />
                <Box width={{ base: "full", md: "33%" }} ml={{ base: 0, md: 5 }} h="10" mb={4}>
                  <NextLink href={getProductPath(product.slug)} passHref>
                    <Button type="submit" w="100%">
                      {t("cart:get-now")}
                    </Button>
                  </NextLink>
                </Box>
              </Flex>
              <Flex alignItems="center">
                <Refund />
                <Text ms={1} opacity="0.7">
                  {t("common:14-day-guarantee")}
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
        {renderProductImage()}
        <Box width={{ base: "100%", md: "90%" }} mx="auto" display={{ base: "block", lg: "none" }}>
          <Flex>
            <ProductPriceTag product={product} />
            <Box width={{ base: "50%", md: "33%" }} ml={{ base: 3, sm: 5 }} h="10" mb={4}>
              <NextLink href={getProductPath(product.slug)} passHref>
                <Button type="submit" w="100%">
                  {t("cart:get-now")}
                </Button>
              </NextLink>
            </Box>
          </Flex>
          <Flex alignItems="center">
            <Refund />
            <Text ms={1} opacity="0.7">
              {t("common:14-day-guarantee")}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  ) : null;
};

export default ProductOfferBlock;
